﻿.keyboard {
    position: fixed;
    bottom: 0;
    left: 50%;
    width: 100%;
    max-width: 450px;
    height: 40vh;
    background: #212121;
    transition: .5s all cubic-bezier(0, 0, 0, 1);
    transform: translate(-50%, 100%);
    z-index: 3;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

    &.keyboard-shown {
        transform: translate(-50%, 0);
        border-radius: 6px 6px 0 0;
    }

    .hg-row {
        height: 100%;
        display: grid;
        overflow-y: hidden;

        .hg-button {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: .3rem;
            border-radius: 12px;
            background: #212121;
            box-shadow: 7px 7px 19px #1d1d1d, -7px -7px 19px #252525;
            font-size: 1rem;
            text-shadow: 0 1px 7px #e6ac0063;
            margin: 1.5px;
            border-radius: 6px;
            border: 1px solid #424242;

            &:active {
                box-shadow: none;
                outline: none;
            }

            img {
                width: 20px;
                height: 20px;
            }
        }
    }

    &.keyboard-bet {
        .hg-row {
            height: 100%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(4, 1fr);
            overflow-y: hidden;

            .hg-button:nth-child(4) {
                font-size: 90%;
                text-align: center;
            }

            .hg-button[data-skbtn="{add}"] {
                grid-area: 1/4/span 2/4;
            }

            .hg-button:nth-child(14) {
                grid-area: 3/4/span 2/4;
                text-align: center;
            }
        }
    }

    &.keyboard-advance {
        display: flex;
        flex-direction: column;

        .hg-row:nth-child(3) {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            flex: 4
        }

        div[class*='toggled-'] {
            border: 2px solid;
        }

        .hg-row {
            // height: 100%;
            // display: grid;
            // grid-template-columns: repeat(7, 1fr);
            // grid-template-rows: repeat(6, 1fr);
            overflow-y: hidden;
            height: unset;
            display: flex;
            flex: 1;

            .hg-button {
                flex: 1
            }
        }

        .hg-button[data-skbtn="{bksp}"] {
            grid-area: 2/8/span 1/6;
        }

        .hg-button[data-skbtn="{box}"] {
            grid-area: 1/4/span 2/5;
        }

        .hg-button[data-skbtn="{ibox}"] {
            grid-area: 3/5/span 2/4;
        }

        .hg-button[data-skbtn="{del}"] {
            grid-area: 1/7/span 1/5;
        }

        .hg-button[data-skbtn="{nl}"] {
            grid-area: 2/7/span 1/5;
        }

        .hg-button[data-skbtn="{bet}"] {
            grid-area: 3/7/span 2/5;
        }

        .hg-button[data-skbtn="{M}"] {
            grid-area: 1/1;
        }
        .hg-button[data-skbtn="{K}"] {
            grid-area: 1/2;
        }
        .hg-button[data-skbtn="{T}"] {
            grid-area: 1/3;
        }
        .hg-button[data-skbtn="{S}"] {
            grid-area: 1/4;
        }
        .hg-button[data-skbtn="{B}"] {
            grid-area: 1/5;
        }
        .hg-button[data-skbtn="{W}"] {
            grid-area: 1/6;
        }
        .hg-button[data-skbtn="{G}"] {
            grid-area: 1/7;
        }

        .hg-button[data-skbtn="{box}"], .hg-button[data-skbtn="{ibox}"], .hg-button[data-skbtn="{bet}"] {
            font-size: 90%;
        }
    }
}
